
























































































































































import {
  Component, Prop, Mixins, Watch,
} from 'vue-property-decorator';

import Tabs, { TabsMenuInterface } from '@/components/Tabs/Tabs.vue';
import TabsFeedBackPermission from '@/components/FeedbackPermission/TabsFeedbackPermission/index.vue';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import mixinDoubts from '@/mixins/doubts';

import { LIST_PERMISSION } from '@/constant/ListPermission';
import { LIST_NAME_CONTEXT } from '@/constant/Context';
import { IDS_FOR_PERMISSION_COURSE } from '@/constant/CoursesId';
import { LIST_BOOK_ID } from '@/constant/ListBookId';

import { hasPermissionMaterials } from '@/share/Util/Permissions/materials';
import { hasPermissionExercises } from '@/share/Util/Permissions/exercises';
import { hasPermissionVideos } from '@/share/Util/Permissions/videos';

import { CountDoubtsToday, Doubt } from '@/globalInterfaces/Doubts';

import {
  GetDoubts as IGetDoubts,
  GetMyDoubts as IGetMyDoubts,
  SaveDoubts as ISaveDoubts,
} from '@/services/Doubts/DoubtsInterface';

const ListDoubts = () => import('@/components/Doubts/ListDoubts/ListDoubts.vue');
const SendDoubts = () => import('@/components/Doubts/SendDoubts/SendDoubts.vue');
const Comments = () => import('@/components/Comments/Comments.vue');
const Annotations = () => import('@/components/Annotations/Annotations.vue');
const TopicList = () => import('./components/TopicList.vue');

const LIMIT_DOUBTS = 5;
const NUMBER_PAGE_INITIAL = 0;
const TAB_ACTIVE = 0;
const QUANTITY_MAX_SEND_DOUBTS_FOR_DISCIPLINE = 3;
const SUBJECT_NAME_ARCHIVES = 'archives';
const SUBJECT_NAME_VIDEOS = 'videos';
const TYPE_VIDEO = 'video';
const SLUG_DOUBTS = 'doubts';
const ORDER_TABS = {
  numberTabComments: 0,
  numberTabDoubts: 1,
  numberTabAnnotations: 2,
  numberTabListExercise: 3,
  numberTabListVideoOrMaterial: 4,
};

interface OrderTabs {
  numberTabComments: number;
  numberTabDoubts: number;
  numberTabAnnotations: number;
  numberTabListExercise: number;
  numberTabListVideoOrMaterial: number;
}

@Component({
  components: {
    Tabs,
    TopicList,
    ListDoubts,
    SendDoubts,
    Comments,
    Annotations,
    TabsFeedBackPermission,
  },
})
export default class SubjectTools extends Mixins(mixinDoubts) {
  @Prop({ required: true }) tabMenu!: Array<TabsMenuInterface>;
  @Prop({ default: () => (ORDER_TABS) }) orderTabs!: OrderTabs;
  @Prop() listTopic!: Record<string, any>;
  @Prop() type!: string;
  @Prop() contextName!: string;
  @Prop() contextID!: number;
  @Prop({ default: null }) idBook!: null | number;
  @Prop() permissionAnnotions!: boolean;
  @Prop({ default: true }) loading!: boolean;
  @Prop({ default: false }) errorLoadingList!: boolean;

  private subject = '';
  private idTopic = '';
  private fileID = '';
  private quantityDoubtsDay = 0;
  private LIST_NAME_CONTEXT = LIST_NAME_CONTEXT;

  private errorAllDoubts = false;
  private errorMyDoubts = false;
  private isLoadingDoubts = true;
  private allDoubts: Array<Doubt> = [];
  private myDoubts: Array<Doubt> = [];
  private updatedTabMenu: Array<TabsMenuInterface> = [];

  private quantityDoubtsForRequest = LIMIT_DOUBTS;

  private totalAllDoubts = 0;
  private totalMyDoubts = 0;

  private orderAnnotations = {};
  private listDoubts: Array<Doubt> = [];

  async mounted() {
    this.setListMenu();
    this.setSubject();
    this.setFileID();
    this.setOrderAnnotations();
  }

  get tabIndexCommentsOrAnnotations() {
    return this.tabIndex === this.updatedOrderTabs.numberTabComments
          || this.tabIndex === this.updatedOrderTabs.numberTabAnnotations;
  }

  get tabIndex() {
    return this.updatedTabMenu.findIndex((tab: TabsMenuInterface) => tab.active);
  }

  get isAvailableSend() {
    return this.hasUnlimitedDoubts
        || this.quantityDoubtsDay < QUANTITY_MAX_SEND_DOUBTS_FOR_DISCIPLINE;
  }

  get permissionsTab() {
    return {
      doubts: this.hasPermissionCredits || this.hasPermission,
      comments: true,
      notes: this.type === TYPE_VIDEO ? this.permissionAnnotions : true,
      exercises: this.permissionExercises,
      materials: this.permissionMaterial,
      videos: this.permissionVideos,
    };
  }

  get permissionVideos() {
    return this.listTopic.videos && this.listTopic.videos.length ? this.listTopic.videos.some((video: Record<string, any>) => hasPermissionVideos(video)) : true;
  }

  get permissionExercises() {
    return this.listTopic.exercices && this.listTopic.exercices.length ? this.listTopic.exercices.some((exercise: Record<string, any>) => hasPermissionExercises(exercise)) : true;
  }

  get permissionMaterial() {
    if (this.contextName === LIST_NAME_CONTEXT.PLANNER) {
      return this.can(LIST_PERMISSION.PLANNER);
    }

    if (this.contextName === LIST_NAME_CONTEXT.COURSE) {
      return this.can(IDS_FOR_PERMISSION_COURSE[Number(this.idCourseContext)]);
    }

    if (
      this.idBook === LIST_BOOK_ID.REDACTION
      && this.can(LIST_PERMISSION.ALL_MATERIALS_REDACTION)
    ) return true;

    return this.listTopic.archives && this.listTopic.archives.length ? this.listTopic.archives.some((material: Record<string, any>) => hasPermissionMaterials(material)) : true;
  }

  get idCourseContext() {
    const { path } = this.$route.params;
    const splitPath = path.split('&');

    return splitPath[1];
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get showDoubts() {
    return this.can(LIST_PERMISSION.DOUBTS_FEATURE);
  }

  get updatedOrderTabs() {
    if (this.showDoubts) return this.orderTabs;

    if (this.type === TYPE_VIDEO) {
      return {
        numberTabAnnotations: 0,
        numberTabListExercise: 1,
        numberTabListVideoOrMaterial: 2,
      };
    }

    return {
      numberTabComments: 0,
      numberTabAnnotations: 1,
      numberTabListExercise: 2,
      numberTabListVideoOrMaterial: 3,
    };

  }

  setListMenu() {
    this.updatedTabMenu = this.showDoubts
      ? this.tabMenu
      : this.tabMenu.filter((menu: TabsMenuInterface) => menu?.slug !== SLUG_DOUBTS).map((menuItem, index) => {
        if (index === 0) {
          return {
            ...menuItem,
            active: true,
          };
        }
        return menuItem;
      });
  }

  @Watch('$store.getters.tabs', {
    deep: true,
  })
  getSelectedTabSidebarToOpen() {
    const NAME_COMPONENT = 'SubjectTools';
    const tabStore = this.$store.getters.tabs;
    const indexTabNameInComponent = this.updatedTabMenu.findIndex((tab: TabsMenuInterface) => tab.title === tabStore.tabName);

    if (tabStore && tabStore.component === NAME_COMPONENT && indexTabNameInComponent !== -1) {
      this.updatedTabMenu = this.updatedTabMenu.map((tab: TabsMenuInterface) => {
        if (tab.title === tabStore.tabName) return { ...tab, active: true };
        return { ...tab, active: false };
      });
    }
  }

  changeTabActive(index: number, item: Array<TabsMenuInterface>) {
    this.updatedTabMenu = item;
  }

  @Watch('$route.params.topicID')
  setSubject() {
    this.subject = this.setPath(this.type);
    this.idTopic = this.$route.params.topicID;
  }

  setPath(type: string): string {
    if (type === 'material') return 'videos';
    if (type === 'exercise') return 'exercises';
    return 'archives';
  }

  @Watch('$route.params.fileID')
  setFileID() {
    this.fileID = this.$route.params.fileID;
  }

  @Watch('$store.getters.file', {
    deep: true,
  })
  setTimeVideo() {
    return this.$store.getters.file.time;
  }

  @Watch('$store.getters.order', {
    deep: true,
  })
  setOrderAnnotations() {
    if (this.subject === SUBJECT_NAME_ARCHIVES) {
      this.orderAnnotations = {
        order: this.$store.getters.order.pdf,
      };
    }

    if (this.subject === SUBJECT_NAME_VIDEOS) {
      this.orderAnnotations = {
        order: this.$store.getters.order.video,
        time: this.$store.getters.order.time,
      };
    }
  }

  setQueryDoubts(page: number, tabActive: number) {
    const topicID = this.listTopic?.idTopic;
    const isAllDoubts = tabActive === 0;

    const query: IGetDoubts | IGetMyDoubts = {
      ...(topicID && { topicID: [Number(topicID)] }),
      ...(this.contextID && { disciplineID: [Number(this.contextID)] }),
      ...(LIMIT_DOUBTS >= 0 && { limit: LIMIT_DOUBTS }),
      ...((page || page >= 0) && { page }),
      orderby: 'desc',
      logged: true,
    };

    if (isAllDoubts) this.getAllDoubts(query);
    else this.getMyDoubts(query);
  }

  async getAllDoubts(query: IGetDoubts) {
    try {
      this.isLoadingDoubts = true;

      const { doubts, totalDoubts } = await this.DoubtsService.getDoubts(query);

      if (doubts && Object.entries(doubts).length) {
        this.totalAllDoubts = totalDoubts;

        this.allDoubts = [...this.allDoubts, ...doubts];
      }
    } catch (error) {
      this.errorAllDoubts = true;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as dúvidas.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingDoubts = false;
    }
  }

  async getMyDoubts(query: IGetMyDoubts) {
    try {
      const { doubts, totalDoubts } = await this.DoubtsService.getMyDoubts(query);

      if (doubts && doubts.length) {
        this.totalMyDoubts = totalDoubts;

        this.myDoubts = [...this.myDoubts, ...doubts];
      }
    } catch (error) {
      this.errorMyDoubts = true;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar suas dúvidas.',
        status: 'error',
      });

      console.error(error);
    }
  }

  async sendDoubt(content: string) {
    try {
      const topicID = this.listTopic?.idTopic;

      const doubt: ISaveDoubts = {
        content,
        disciplineID: Number(this.contextID),
        userType: 1,
        ...(topicID && { topicID: Number(topicID) }),
      };

      const response = await this.DoubtsService.saveDoubts(doubt);

      if (response?.status === STATUS_RESPONSE_API.SUCCESS_CREATED) {
        const { profile } = this.$store.getters;

        const newDoubt: Doubt = {
          ID: response?.data || 0,
          status: 0,
          content: doubt.content,
          disciplineID: doubt.disciplineID,
          topicID: doubt?.topicID || 0,
          userID: profile?.idstudent || 0,
          user_type: doubt.userType,
          registry_date: new Date(),
          color: '',
          monitorID: 0,
          monitorName: null,
          topicName: null,
          disciplineName: '',
          contentfix: doubt.content,
          replyCount: 0,
          profile: {
            idstudent: profile?.idstudent || 0,
            usersname: profile?.name || '',
            avatar: profile?.avatar || '',
          },
        };

        this.allDoubts = [newDoubt, ...this.allDoubts];
        this.myDoubts = [newDoubt, ...this.myDoubts];

        this.quantityDoubtsDay += 1;

        return true;
      }

      return false;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao salvar uma dúvida.',
        status: 'error',
      });

      console.error(error);

      return false;
    }
  }

  @Watch('listTopic', {
    deep: true,
  })
  @Watch('listDoubts', {
    deep: true,
  })
  @Watch('contextID')
  setListDoubts() {
    if (!this.contextID || this.contextID < 0) return;

    this.allDoubts = [];
    this.myDoubts = [];

    const topicID = this.listTopic?.idTopic;

    const query: IGetMyDoubts = {
      ...(topicID && { topicID: [Number(topicID)] }),
      ...(this.contextID && { disciplineID: [Number(this.contextID)] }),
      ...(LIMIT_DOUBTS >= 0 && { limit: LIMIT_DOUBTS }),
      ...(NUMBER_PAGE_INITIAL >= 0 && { page: NUMBER_PAGE_INITIAL }),
      logged: true,
    };

    this.setQueryDoubts(NUMBER_PAGE_INITIAL, TAB_ACTIVE);
    this.getMyDoubts(query);
  }

  @Watch('contextID', {
    immediate: true,
  })
  async getQuantityDoubtsToday() {
    try {
      this.quantityDoubtsDay = 0;

      const response: CountDoubtsToday[] = await this.DoubtsService.getQuantityDoubtsToday();

      if (!response || !response.length) return;

      const foundDiscipline = response.find((countDiscipline: Record<string, any>) => countDiscipline.disciplineID === this.contextID);

      if (foundDiscipline) {
        this.quantityDoubtsDay = foundDiscipline?.count;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar quantidade de dúvidas.',
        status: 'error',
      });

      console.error(error);
    }
  }

  reloadPage() {
    window.location.reload();
  }
}
