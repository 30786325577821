
























import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import ContainerSidebar from '@/components/ContainerSidebar/ContainerSidebar.vue';
import SidebarAccordion from '@/components/SidebarAccordion/index.vue';
import ErrorLoading from '@/components/ErrorLoading/ErrorLoading.vue';
import SidebarAccordionLoading from '@/components/SidebarAccordionLoading/index.vue';

@Component({
  components: {
    ContainerSidebar,
    SidebarAccordion,
    ErrorLoading,
    SidebarAccordionLoading,
  },
})
export default class MaterialsSidebar extends Vue {
  @Prop() path!: string;
  @Prop() data!: Array<Record<string, any>>;
  @Prop() context!: string;
  @Prop() isLoading!: boolean;

  get setLink() {
    if (this.context === 'planner') {
      return '/planner';
    }

    if (this.context === 'cursos') {
      const splitPath = this.path.split('&');
      const slugCourse = splitPath[0];
      const idCourses = splitPath[1];
      const idClass = splitPath[2];

      return `/cursos/${slugCourse}/${idCourses}/${idClass}`;
    }

    return `/caderno/${this.path}`;
  }
}
