







































































import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

import * as pdfjsLib from 'pdfjs-dist';
import printJS from 'print-js';

import Plus from '@/assets/icons/Plus.vue';
import Minus from '@/assets/icons/Minus.vue';
import Arrow from '@/assets/icons/Arrow.vue';
import Download from '@/assets/icons/Download.vue';
import Print from '@/assets/icons/Print.vue';

import ErrorLoading from '@/components/ErrorLoading/ErrorLoading.vue';

import { setNumber } from '@/share/Util/UsefulFunctions/UserfulFunctions';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js';

const INITIAL_NUMBER_PAGES = 1;
const COUNT_NUMBER_PAGES = 1;
const STANDARD_ZOOM = 1;
const RESPONSIVE_ZOOM_SMALL = 0.5;
const RESPONSIVE_ZOOM_MEDIUM = 1;
const COUNT_ZOOM = 0.5;

@Component({
  components: {
    Plus,
    Minus,
    Arrow,
    Download,
    Print,
    ErrorLoading,
  },
  filters: { setNumber },
})

export default class PdfViewer extends Vue {
  @Prop() pdf!: string;
  @Prop({ default: false }) showDownload!: boolean;
  @Prop({ default: false }) showPrint!: boolean;

  private renderPdf: any;
  private currentPage = INITIAL_NUMBER_PAGES;
  private numPages = INITIAL_NUMBER_PAGES;
  private pageRendering = false;
  private pageNumPending = null;
  private zoom = STANDARD_ZOOM;

  mounted() {
    this.readPDF();
    this.setOrderPage();
  }

  @Watch('currentPage')
  setOrderPage() {
    this.$store.commit('setOrder', { pdf: this.currentPage });
  }

  @Watch('pdf')
  readPDF(): void {
    this.currentPage = INITIAL_NUMBER_PAGES;
    pdfjsLib.getDocument(this.pdf).promise.then((pdfDoc_: any) => {
      this.renderPdf = pdfDoc_;
      this.numPages = this.renderPdf.numPages;

      this.renderPage(this.currentPage);
    });
  }

  renderPage(num: any) {
    this.pageRendering = true;

    this.renderPdf.getPage(num).then((page: any) => {
      const canvas = this.$refs.canvasPdf as HTMLCanvasElement;

      const context = canvas.getContext('2d');
      const viewport = page.getViewport({ scale: this.zoom });

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const loadingTask = page.render({
        canvasContext: context,
        viewport,
      });

      loadingTask.promise.then(() => {
        this.pageRendering = false;
        if (this.pageNumPending !== null) {
          this.renderPage(this.pageNumPending);
          this.pageNumPending = null;
        }
      });
    });
  }

  queueRenderPage(num: any) {
    if (this.pageRendering) this.pageNumPending = num;
    else this.renderPage(num);
  }

  previousPage() {
    if (this.renderPdf === null || this.currentPage <= INITIAL_NUMBER_PAGES) return;
    this.currentPage -= COUNT_NUMBER_PAGES;
    this.queueRenderPage(this.currentPage);
  }

  nextPage(): void {
    if (this.renderPdf === null || this.currentPage >= this.numPages) return;
    this.currentPage += COUNT_NUMBER_PAGES;
    this.queueRenderPage(this.currentPage);
  }

  printOut(): void {
    this.setTrackAmplitude('Imprimir');

    printJS({
      printable: this.pdf, type: 'pdf', showModal: true, modalMessage: 'Processando PDF...',
    });
  }

  zoomIn(): void {
    if (this.renderPdf === null) return;
    this.zoom += COUNT_ZOOM;
    this.queueRenderPage(this.currentPage);
  }

  zoomOut(): void {
    if (this.renderPdf === null) return;

    if (this.zoom > STANDARD_ZOOM) {
      this.zoom -= COUNT_ZOOM;
      this.queueRenderPage(this.currentPage);
    }
  }

  @Watch('$responsive.isMobile', {
    immediate: true,
  })
  responsiveZoom(): void {
    if (window.innerWidth < 768 && this.zoom !== RESPONSIVE_ZOOM_SMALL) {
      this.zoom = RESPONSIVE_ZOOM_SMALL;
      this.queueRenderPage(this.currentPage);
      return;
    }

    if (window.innerWidth > 768 && this.zoom !== RESPONSIVE_ZOOM_MEDIUM) {
      this.zoom = RESPONSIVE_ZOOM_MEDIUM;
      this.queueRenderPage(this.currentPage);
    }
  }

  setTrackAmplitude(nameLocal: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'PdfViewer',
          local: nameLocal,
          page: this.$route.name,
        },
      },
    });
  }
}
