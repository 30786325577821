



















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class RegroupType extends Vue {
  @Prop() title!: string;
  @Prop({ default: '' }) infoAdditional!: string;
}
