
































import { Component, Vue, Prop } from 'vue-property-decorator';

const DEFAULT_LINK_BUTTON = 'https://pages.explicae.com.br/compare-os-planos';
const DEFAULT_TEXT_BUTTON = 'Quero assinar';

@Component
export default class FeedBackPermission extends Vue {
  @Prop({ required: true }) nameFeature!: string;
  @Prop({ required: false }) title!: string;
  @Prop({ default: DEFAULT_TEXT_BUTTON }) textButton!: string;
  @Prop({ default: DEFAULT_LINK_BUTTON }) linkButtton!: string;

  get titleFeedback() {
    return this.title ? this.title : `Quer ter acesso ${this.nameFeature}?`;
  }

  goToDashboard() {
    this.$router.push({
      name: 'begin',
    });
  }

}
