import { Mixins, Component } from 'vue-property-decorator';

import permission from './permission';

import { LIST_PERMISSION } from '@/constant/ListPermission';

const DOUBTS_UNLIMITED_QUANTITY = 99999;

@Component
export default class Permission extends Mixins(permission) {

  created() {
    this.getQuantityDoubts();
  }

  get hasPermission() {
    return this.can(LIST_PERMISSION.DOUBTS);
  }

  get hasPermissionCredits() {
    return this.can(LIST_PERMISSION.DOUBTS_CREDITS);
  }

  get getTotalCreditsDoubts(): number | null {
    const total = this.getQuantityPermission(LIST_PERMISSION.DOUBTS_CREDITS);

    return total;
  }

  get getRemainingCreditsDoubts() {
    const totalCredits = this.getTotalCreditsDoubts;
    if (!totalCredits) return null;

    const result = totalCredits - this.totalQuantityDoubtsMonthly;
    return result < 0 ? 0 : result;
  }

  get totalQuantityDoubtsMonthly() {
    return this.$store.getters.totalDoubtsMonthly;
  }

  get hasUnlimitedDoubts() {
    return (this.getTotalCreditsDoubts || 0) >= DOUBTS_UNLIMITED_QUANTITY;
  }

  incrementTotalDoubts() {
    this.setStoreQuantityDoubtsMonthly(this.totalQuantityDoubtsMonthly + 1);
  }
}
